<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-address-book" /> Accounts
          <div class="card-header-actions">
            <small class="text-muted">Total: {{ accounts.length }}</small>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="accounts"
          :fields="accountFields"
          :hover="hover"
          column-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          pagination>
          <template #flagV="{ item }">
            <td>
              <CBadge :color="$config.userClr(item.flags)">{{
                $config.userTxt(item.flags)
              }}</CBadge>
            </td>
          </template>
          <template #product="{ item }">
            <td class="py-2">
              <span v-if="item.product !== null">{{ item.product }}</span>
            </td>
          </template>
           <template #discord="{ item }">
            <td class="py-2">
              <span v-if="item.discord !== null">{{ item.discord }}</span>
            </td>
          </template>
          <template #manage="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="manageAccount(item.id)"
              >
                Manage
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Accounts",
  components: {
    Loading,
  },
  data() {
    return {
      accounts: [],
      accountFields: [
        { key: "id", label: "ID", filter: true, sorter: false },
        { key: "login", label: "Login" },
        { key: "flagV", label: "Flags", filter: false, sorter: false },
        { key: "email", label: "Email" },
        { key: "discord", label: "Discord" },
        { key: "product", label: "Product" },
        { key: "manage", label: "", filter: false, sorter: false },
      ],
      hover: true,
      isLoading: false,
    };
  },
  methods: {
    getUsers() {
      this.isLoading = true;
      this.$http
        .get(this.$config.gmapi + "/user", {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.accounts = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    },
    manageAccount(id) {
      this.$router.push({ name: "Account", params: { id: id } });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
</style>
